<template>
  <div class="agreement wrapper">
    <div class="p-content">
      <p>NetBang is an online retail service offered by NetBang Development Team</p>

      <p><b>Service provider</b></p>
      <p>NetBang services are provided by, and you’re contracting with NetBang Developerment Team.</p>

      <p><b>Retail Purchase</b></p>
      <p>
        NetBang may offer or require a Subscription for purchasers of retail packaged product versions or OEM versions of video games played on your PC Console. The "CD-Key" or "Product Key" accompanying such versions is used to activate accounts of video games you purchased in NetBang.
      </p>
      <p><b>Subscription Rules</b></p>

      <p>
        This service is an automatic renewal service provided to you on the premise that you have opened NetBang Membership. If you open NetBang Membership, it is deemed that you have authorized NetBang Membership to have the right to use your own recharge account, third-party payment account bound to the member account, bank card, communication account, when your NetBang Membership is about to expire. The monthly subscription fee for the next billing cycle will be deducted from the balance of the iTunes account (hereinafter collectively referred to as the "account"). The premise of this service is that you have bound your NetBang Account to the above account and can successfully deduct money from the above account. The renewal failure caused by the insufficient deductible balance in the above account shall be borne by you.
      </p>
      <p>
        NetBang Membership has three subscription methods to choose from:
      </p>
      <p>
        The first type: $9.99 per month, automatically renewed every 1 month.
      </p>
      <p>
        The second type: $23.99 / 3 months, automatic renewal every 3 months.
      </p>
      <p>
        The third type: #58.99 / 12 months, automatic renewal every 12 months.
      </p>
      <p>
        For accounts that purchase automatic renewal of NetBang Membership, the service will be automatically renewed and the corresponding fees will be deducted on the next day after the expiration of the purchased service period. If the 3-month NetBang Membership automatic renewal service is opened on January 3, NetBang Membership will automatically deduct fees from the account on April 3 and extend the validity of NetBang Membership for 3 months. In the case of purchasing automatic renewal of NetBang Membership in different months at different points in time, the last automatic renewal of NetBang Membership will prevail. If the automatic renewal of NetBang Membership has been activated for 1 month, and the automatic renewal of NetBang Membership has been activated for 3 months this month, the account will be automatically deducted and extended for 3 months NetBang on the day after the expiration of the 3-month NetBang Membership service period Membership validity period.
      </p>
      <p>
        If you need to cancel the automatic renewal, please cancel at least 24 hours in advance. You can suspend or terminate this service in the following ways:
      </p>
      <p>
        Apple in-app payment (IAP payment, in-app purchase) automatic renewal user cancellation method is as follows: Open the Apple phone "Settings" --> enter "iTunes Store and App Store" --> click "Apple ID", select "View" Apple ID", enter the "Account Settings" page, click "Subscribe", and select NetBang Membership to cancel the subscription.
      </p>

      <p><b>Contents</b></p>
      <p>
        Some contents and services that are authored by Valve and some third-party paterners, we do not screen such third-party content available on NetBang or through other sources. NetBang assumes no responsibility or liability for such third party content. Some third-party application software is capable of being used by businesses for business purposes - however, you may only acquire such software via Steam https://store.steampowered.com/ for private personal use.
      </p>

      <p><b>General Content and Services License</b></p>
      <p>
        By purchasing license of Content and Services , it requires the download and installation of Content and Services onto your PC computer. By authorized by some third-part paterner in legal, NetBang hereby grants, and you accept, a non-exclusive license and right, to use the Content and Services for your personal, non-commercial use (except where commercial use is expressly allowed herein or in the applicable Subscription Terms). The Content and Services are licensed, not sold. Your license confers no title or ownership in the Content and Services.
      </p>

      <p><b>Your relationship with NetBang</b></p>
      <p>
        These terms help define the relationship between you and NetBang. Broadly speaking, we give you permission to use our services if you agree to follow these terms, which reflect how NetBang’s business works and how we earn money. When we speak of “NetBang,” “we,” “us,” and “our,” we mean NetBang team and its affiliates.
      </p>

      <p><b>Permission to use your content</b></p>
      <p>
        Some of our services are designed to let you upload, submit, store, send, receive, or share your content. You have no obligation to provide any content to our services and you’re free to choose the content that you want to provide. If you choose to upload or share content, please make sure you have the necessary rights to do so and that the content is lawful.
      </p>

      <p><b>License</b></p>
      <p>
        Your content remains yours, which means that you retain any intellectual property rights that you have in your content. For example, you have intellectual property rights in the creative content you make, such as reviews you write. Or you may have the right to share someone else’s creative content if they’ve given you their permission.
      </p>
      <p>
        We need your permission if your intellectual property rights restrict our use of your content. You provide NetBang with that permission through this license.
      </p>

      <p><b>What’s covered</b></p>
      <p>
        This license covers your content if that content is protected by intellectual property rights.
      </p>

      <p><b>What’s not covered</b></p>
      <ul>
        <li>This license doesn’t affect your privacy rights — it’s only about your intellectual property rights</li>
        <li>This license doesn’t cover these types of content:</li>
        <li>publicly-available factual information that you provide, such as corrections to the address of a local business. That information doesn’t require a license because it’s considered common knowledge that everyone’s free to use.</li>
        <li>feedback that you offer, such as suggestions to improve our services. Feedback is covered in the Service-related communications section below.</li>
      </ul>

      <p><b>Scope</b></p>
      <p>This license is:</p>
      <ul>
        <li>worldwide, which means it’s valid anywhere in the world</li>
        <li>non-exclusive, which means you can license your content to others</li>
        <li>royalty-free, which means there are no fees for this license</li>
      </ul>

      <p><b>Rights</b></p>
      <p>This license allows NetBang to:</p>
      <ul>
        <li>host, reproduce, distribute, communicate, and use your content — for example, to save your content on our systems and make it accessible from anywhere you go</li>
        <li>publish, publicly perform, or publicly display your content, if you’ve made it visible to others</li>
        <li>modify and create derivative works based on your content, such as reformatting or translating it</li>
        <li>sublicense these rights to:</li>
        <li>other users to allow the services to work as designed, such as enabling you to share photos with people you choose</li>
        <li>our contractors who’ve signed agreements with us that are consistent with these terms, only for the limited purposes described in the Purpose section below</li>
      </ul>

      <p><b>Purpose</b></p>
      <p>This license is for the limited purpose of:</p>
      <ul>
        <li>operating and improving the services, which means allowing the services to work as designed and creating new features and functionalities. This includes using automated systems and algorithms to analyze your content:</li>
        <li>for spam, malware, and illegal content</li>
        <li>to recognize patterns in data, such as determining when to suggest a new album in NetBang Photos to keep related photos together</li>
        <li>to customize our services for you, such as providing recommendations and personalized search results, content.</li>
      </ul>

      <p>
        This analysis occurs as the content is sent, received, and when it is stored.
      </p>
      <ul>
        <li>using content you’ve shared publicly to promote the services. For example, to promote a NetBang app, we might quote a review you wrote. Or to promote NetBang, we might show a screenshot of the app you offer in Netbang.</li>
        <li>developing new technologies and services for NetBang consistent with these terms</li>
      </ul>

      <p><b>Duration</b></p>
      <p>
        This license lasts for as long as your content is protected by intellectual property rights.
      </p>
      <p>
        If you remove from our services any content that’s covered by this license, then our systems will stop making that content publicly available in a reasonable amount of time. There are two exceptions:
      </p>
      <ul>
        <li>
          If you already shared your content with others before removing it. For example, if you shared a photo with a friend who then made a copy of it, or shared it again, then that photo may continue to appear in your friend’s NetBang Account even after you remove it from your NetBang Account.
        </li>
        <li>
          If you make your content available through other companies’ services, it’s possible that search engines, including NetBang Search, will continue to find and display your content as part of their search results.
        </li>
      </ul>

      <p><b>Using NetBang services</b></p>
      <p>Your NetBang Account</p>
      <p>
        If you meet these age requirements you can create a NetBang Account for your convenience. Some services require that you have a NetBang Account in order to work — for example, to use Gmail, you need a NetBang Account so that you have a place to send and receive your email.
      </p>
      <p>
        You’re responsible for what you do with your NetBang Account, including taking reasonable steps to keep your NetBang Account secure, and we encourage you to regularly use the Security Checkup.
      </p>
      <p>
        Using NetBang services on behalf of an organization
      </p>
      <p>
        Many organizations, such as businesses, non-profits, and schools, take advantage of our services. To use our services on behalf of an organization:
      </p>
      <ul>
        <li>an authorized representative of that organization must agree to these terms</li>
        <li>your organization’s administrator may assign a NetBang Account to you. That administrator might require you to follow additional rules and may be able to access or disable your NetBang Account.</li>
      </ul>

      <p><b>Service-related communications</b></p>
      <p>
        To provide you with our services, we sometimes send you service announcements and other information. To learn more about how we communicate with you, see NetBang’s Privacy Policy.
      </p>
      <p>
        If you choose to give us feedback, such as suggestions to improve our services, we may act on your feedback without obligation to you.
      </p>

      <p><b>Content in NetBang services</b></p>
      <p>Your content</p>
      <p>
        Some of our services give you the opportunity to make your content publicly available — for example, you might post a product or restaurant review that you wrote, or you might upload a blog post that you created.
      </p>
      <ul>
        <li>
          See the Permission to use your content section for more about your rights in your content, and how your content is used in our services
        </li>
        <li>
          See the Removing your content section to learn why and how we might remove user-generated content from our services
        </li>
      </ul>
      <p>
        If you think someone is infringing your intellectual property rights, you can send us notice of the infringement and we’ll take appropriate action. For example, we suspend or close the NetBang Accounts of repeat copyright infringers as described in our Copyright Help Center.
      </p>

      <p><b>NetBang content</b></p>
      <p>
        Some of our services include content that belongs to NetBang — for example, many of the visual illustrations you see in NetBang Maps. You may use NetBang’s content as allowed by these terms and any service-specific additional terms, but we retain any intellectual property rights that we have in our content. Don’t remove, obscure, or alter any of our branding, logos, or legal notices. If you want to use our branding or logos, please see the NetBang Brand Permissions page.
      </p>

      <p><b>Other content</b></p>
      <p>
        Finally, some of our services give you access to content that belongs to other people or organizations — for example, a store owner’s description of their own business, or a newspaper article displayed in NetBang News. You may not use this content without that person or organization’s permission, or as otherwise allowed by law. The views expressed in other people or organizations’ content are theirs, and don’t necessarily reflect NetBang’s views.
      </p>

      <p><b>Software in NetBang services</b></p>
      <p>
        Some of our services include downloadable video games from some PC platform. We give you permission to use that software as part of the services after purchasing the license from our store.
      </p>
      <p>The license we give you is:</p>
      <ul>
        <li>worldwide, which means it’s valid anywhere in the world</li>
        <li>non-exclusive, which means that we can license the software to others</li>
        <li>royalty-free, which means there are no fees for this license</li>
        <li>personal, which means it doesn’t extend to anyone else</li>
        <li>non-assignable, which means you’re not allowed to assign the license to anyone else</li>
      </ul>

      <p>
        Some of our services include software that’s offered under open source license terms that we make available to you. Sometimes there are provisions in the open source license that explicitly override parts of these terms, so please be sure to read those licenses.
      </p>
      <p>
        You may not copy, modify, distribute, sell, or lease any part of our services or software. Also, you may not reverse engineer or attempt to extract any of our source code unless you have our written permission or applicable law lets you do so.
      </p>
      <p>
        When a service requires or includes downloadable software, that software sometimes updates automatically on your device once a new version or feature is available. Some services let you adjust your automatic update settings.
      </p>

      <p><b>In case of problems or disagreements</b></p>
      <p>
        By law, you have the right to (1) a certain quality of service, and (2) ways to fix problems if things go wrong. These terms don’t limit or take away any of those rights. For example, if you’re a consumer, then you continue to enjoy all legal rights granted to consumers under applicable law.
      </p>

      <p><b>Warranty</b></p>
      <p>
        We provide our services using reasonable skill and care. If we don’t meet the quality level described in this warranty, you agree to tell us and we’ll work with you to try to resolve the issue.
      </p>

      <p><b>Disclaimers</b></p>
      <p>
        The only commitments we make about our services (including the content in the services, the specific functions of our services, or their reliability, availability, or ability to meet your needs) are (1) described in the Warranty section, (2) stated in the service-specific additional terms, or (3) provided under applicable laws. We don’t make any other commitments about our services.
      </p>
      <p>
        And unless required by law, we don’t provide implied warranties, such as the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
      </p>

      <p><b>Liabilities</b></p>

      <p>For all users</p>
      <p>
        These terms only limit our responsibilities as allowed by applicable law. Specifically, these terms don’t limit NetBang’s liability for death or personal injury, fraud, fraudulent misrepresentation, gross negligence, or willful misconduct.
      </p>
      <p>
        Other than the rights and responsibilities described in this section (In case of problems or disagreements), NetBang won’t be responsible for any other losses, unless they’re caused by our breach of these terms or service-specific additional terms.
      </p>

      <p><b>Settling disputes, governing law, and courts</b></p>
      <p>For information about how to contact NetBang, please visit our contact page.</p>
      <p>
        California law will govern all disputes arising out of or relating to these terms, service-specific additional terms, or any related services, regardless of conflict of laws rules. These disputes will be resolved exclusively in the federal or state courts of Santa Clara County, California, USA, and you and NetBang consent to personal jurisdiction in those courts.
      </p>
      <p>
        To the extent that applicable local law prevents certain disputes from being resolved in a California court, then you can file those disputes in your local courts. Likewise, if applicable local law prevents your local court from applying California law to resolve these disputes, then these disputes will be governed by the applicable local laws of your country, state, or other place of residence.
      </p>

      <p><b>About these terms</b></p>
      <p>
        By law, you have certain rights that can’t be limited by a contract like these terms of service. These terms are in no way intended to restrict those rights.
      </p>
      <p>
        These terms describe the relationship between you and NetBang. They don’t create any legal rights for other people or organizations, even if others benefit from that relationship under these terms.
      </p>
      <p>
        We want to make these terms easy to understand, so we’ve used examples from our services. But not all services mentioned may be available in your country.
      </p>
      <p>
        If these terms conflict with the service-specific additional terms, the additional terms will govern for that service.
      </p>
      <p>
        If it turns out that a particular term is not valid or enforceable, this will not affect any other terms.
      </p>
      <p>
        If you don’t follow these terms or the service-specific additional terms, and we don’t take action right away, that doesn’t mean we’re giving up any rights that we may have, such as taking action in the future.
      </p>
      <p>
        We may update these terms and service-specific additional terms (1) to reflect changes in our services or how we do business — for example, when we add new services, features, technologies, pricing, or benefits (or remove old ones), (2) for legal, regulatory, or security reasons, or (3) to prevent abuse or harm.
      </p>
      <p>
        If we materially change these terms or service-specific additional terms, we’ll provide you with reasonable advance notice and the opportunity to review the changes, except (1) when we launch a new service or feature, or (2) in urgent situations, such as preventing ongoing abuse or responding to legal requirements. If you don’t agree to the new terms, you should remove your content and stop using the services. You can also end your relationship with us at any time by closing your NetBang Account.
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>
<style lang="less">
@import "~@/assets/styles/agreement.less";
</style>
<style lang="less" scoped>
.wrapper {
  padding: 0 5%;
  background: #f0f0f0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  min-height: 100vh;
  box-sizing: border-box;
}
</style>
